import React from 'react';
import "./styles/style.css"
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './pages/home.page';
import VehiculosPage from './pages/vehiculos.page';

const QuienesSomos: React.FC = () => {
  return (
    <div>
      <header className="App-header">
        <h1>Quiénes Somos</h1>
        <p>Esta es la página de Quiénes Somos.</p>
      </header>
    </div>
  );
}

const Contacto: React.FC = () => {
  return (
    <div>
      <header className="App-header">
        <h1>Contacto</h1>
        <p>Esta es la página de Contacto.</p>
      </header>
    </div>
  );
}

const NotFound: React.FC = () => {
  return (
    <div>
      <header className="App-header">
        <h1>404 - Página no encontrada</h1>
        <p>Lo sentimos, la página que buscas no existe.</p>
        <Link to="/" className="App-link">
          Volver al inicio
        </Link>
      </header>
    </div>
  );
}

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/vehiculos" element={<VehiculosPage />} />
          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

