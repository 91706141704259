import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo_hyundai from "../assets/01_Home/Icon_Hyundai.svg";
import logo_searh from "../assets/01_Home/Icon_Search.svg";
import icon_toggle from "../assets/01_Home/Burger.svg";
import icon_subir from "../assets/01_Home/Icon_subir.svg";
//VEHICULOS
import kona from "../assets/webp/NUEVA-KONA.webp";
import tucson from "../assets/webp/TUCSON.webp";
import ioniq from "../assets/webp/IONIQ5.webp";
import palisade from "../assets/webp/PALISADE-SPORT4X4AT.webp";
import veneu from "../assets/webp/VENUE.webp";
import creta from "../assets/webp/CRETA.webp";
import hb20s from "../assets/webp/HB20S.webp";
import hb20 from "../assets/webp/HB20.webp";
import staria from "../assets/webp/STARIA-LUJO.webp";
import comerciales from "../assets/webp/GRAND-METRO-TAXI.webp";
//POSVENTA
import garantia from "../assets/02_Hover Nav bar/Posventa/Garantia.webp";
import campania from "../assets/02_Hover Nav bar/Posventa/Campañas.webp";
import mantenimiento from "../assets/02_Hover Nav bar/Posventa/Mantenimiento.webp";
import repuestos from "../assets/02_Hover Nav bar/Posventa/Repuestos.webp";
//WHO WE
import hyundai from "../assets/02_Hover Nav bar/Quienes somos/Hyundai.webp";
import blog from "../assets/02_Hover Nav bar/Quienes somos/Blog.webp";
import contacto from "../assets/02_Hover Nav bar/Quienes somos/PQR.webp";
import astara from "../assets/02_Hover Nav bar/Quienes somos/Astara.webp";

/*eslint-disable */

declare const window: any;

interface MenuLaoutsProps {
  className?: string; // className es opcional
}

const MenuLayouts: React.FC<MenuLaoutsProps> = ({className}) => {
  const [menuHover, setMenuHover] = useState("auto");
  const [menuHeight, setMenuHeight] = useState("auto");
  const [isHovered, setIsHovered] = useState(false); // Estado para controlar si el hover está activo
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = React.useState(
    window.innerHeight
  );

  React.useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Limpieza del event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (item: any) => {
    setMenuHover(item);
    if (!isHovered) {
      // Solo se ejecuta si no está ya en hover
      setMenuHeight("521px");
      setIsHovered(true); // Marcamos el hover como activo
      const element = document.querySelector(`.${className}`);
      console.log(className);
      console.log(element);
      
      if (element) {
        element.classList.add('active');
      }
    }
  };

  const handleMouseLeave = () => {
    if (isHovered) {
      // Solo se ejecuta si estaba en hover
      setMenuHover("auto");
      setMenuHeight("auto");
      setIsHovered(false); // Marcamos el hover como inactivo
      const element = document.querySelector(`.${className}`);
      if (element) {
        element.classList.remove('active');
      }
    }
  };

  return (
    <div
      className={`menu-layout ${isHovered ? "expanded" : ""}`}
      onMouseLeave={handleMouseLeave}
    >
      <div className="content-menu-layout">
        <Link className="logo-menu-layout" to="/">
          <img src={logo_hyundai} alt="Hyundai Logo" />
        </Link>
        <div
          className={`toggle-menu-layout ${isHovered ? "active" : ""}`}
          onClick={() => {
            setIsHovered(!isHovered), setMenuHover("auto");
          }}
        >
          <img src={icon_toggle} alt="" />
        </div>
        <div className="items-menu-layout">
          <Link
            className={`item-menu-layout ${
              menuHover === "vehiculo" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("vehiculo")}
            to="/vehiculos"
          >
            Vehículos
          </Link>
          <div
            className={`item-menu-layout ${
              menuHover === "posventa" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("posventa")}
          >
            Posventa
          </div>
          <div
            className={`item-menu-layout ${
              menuHover === "concesionario" ? "active" : ""
            }`}
            //onMouseEnter={() => handleMouseEnter("concesionario")}
            onMouseEnter={handleMouseLeave}
          >
            Concesionarios
          </div>
          <div
            className={`item-menu-layout ${
              menuHover === "somos" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("somos")}
          >
            Quiénes somos
          </div>
          <div
            className={`item-menu-layout ${
              menuHover === "reserva" ? "active" : ""
            }`}
            //onMouseEnter={() => handleMouseEnter("reserva")}
            onMouseEnter={handleMouseLeave}
          >
            Reserva ya
          </div>
        </div>
        <div className="searh-menu-layout">
          <img src={logo_searh} alt="Search Logo" />
        </div>
      </div>
      <div
        className={`vehiculos-menu-layout ${
          menuHover === "vehiculo" ? "active" : ""
        }`}
      >
        <div className="vehiculos-content-menu-layout">
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${kona})` }}
          >
            KONA
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${tucson})` }}
          >
            TUCSON
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${ioniq})` }}
          >
            IONIQ
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${palisade})` }}
          >
            PALISADE
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${veneu})` }}
          >
            VENUE
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${creta})` }}
          >
            CREATE
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${hb20s})` }}
          >
            HB20S
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${hb20})` }}
          >
            HB20
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${staria})` }}
          >
            STARIA
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${comerciales})` }}
          >
            COMERCIALES
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
        </div>
        <div className="categories-content-menu-layout">
        <Link to="/vehiculos" className="category-content-menu-layout">Todos los vehículos</Link> 
          <div className="category-content-menu-layout">Fichas técnicas</div>
          <div className="category-content-menu-layout">
            Agenda tu Cita Taller
          </div>
          <div className="category-content-menu-layout">Comparador</div>
          <div className="category-content-menu-layout">
            Explora la experiencia
          </div>
          <div className="category-content-menu-layout">
            Interactive Car Studio
          </div>
        </div>
      </div>
      <div
        className={`posventas-menu-layout ${
          menuHover === "posventa" ? "active" : ""
        }`}
      >
        <div className="posventas-content-menu-layout">
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${garantia})` }}
          >
            <span>Garantía</span>
            <div className="hover-posventa-content-menu-layout"></div>
          </div>
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${campania})` }}
          >
            <span>Campañas de seguridad</span>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${mantenimiento})` }}
          >
            <span>Mantenimiento</span>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${repuestos})` }}
          >
            <span>Repuestos genuinos</span>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
        </div>
        <div className="categories-content-menu-layout">
          <div className="category-content-menu-layout">
            Manuales de garantía
          </div>
          <div className="category-content-menu-layout">
            Campañas disponibles
          </div>
          <div className="category-content-menu-layout">Hojas de rescate</div>
          <div className="category-content-menu-layout">
            Agendar Cita Taller
          </div>
        </div>
      </div>
      <div
        className={`who-we-menu-layout ${
          menuHover === "somos" ? "active" : ""
        }`}
      >
        <div className="who-we-are-content-menu-layout">
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${hyundai})` }}
          >
            <span>Sobre Hyundai</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${blog})` }}
          >
            <span>Blog y novedades</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${contacto})` }}
          >
            <span>Escríbenos</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${astara})` }}
          >
            <span>Escríbenos</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
        </div>
      </div>
      <div className="content-movil-menu-layout">
        <div className="content-movil-items-menu-layout">
          <div className="content-movil-item-menu-layout">
            <div
              className={`content-movil-item-label-menu-layout ${menuHover === "Vehículos" ? "active" : ""}`}
              onClick={() => setMenuHover(menuHover !== "Vehículos" ? "Vehículos" : "auto")}
            >
              Vehículos <img src={icon_subir} alt="" />
            </div>
            <div className={`content-movil-item-vehiculos-menu-layout ${menuHover === "Vehículos" ? "active" : ""}`}>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${kona})` }}
              >
                KONA
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${tucson})` }}
              >
                TUCSON
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${ioniq})` }}
              >
                IONIQ
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${palisade})` }}
              >
                PALISADE
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${veneu})` }}
              >
                VENEU
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${creta})` }}
              >
                CREATE
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${hb20s})` }}
              >
                HB20S
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${hb20})` }}
              >
                HB20
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${staria})` }}
              >
                STARIA
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
              <div
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${comerciales})` }}
              >
                COMERCIALES
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </div>
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Posventa")}
            >
              Posventa <img src={icon_subir} alt="" />
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Concesionarios")}
            >
              Concesionarios <img src={icon_subir} alt="" />
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Quiénes somos")}
            >
              Quiénes somos
              <img src={icon_subir} alt="" />
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Reserva ya")}
            >
              Reserva ya <img src={icon_subir} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuLayouts;
