import React, {useState} from 'react';
import MenuLayouts from '../layouts/menu.layout';
import FooterLayouts from '../layouts/footer.layout';
import BannerComponent from '../components/vehiculos/banner.component';
import CategoriesComponent from '../components/vehiculos/categories.component';
import ContenidoComponent from '../components/vehiculos/contenido.component';

const VehiculosPage: React.FC = () => {
    const [selectedCategories, setSelectedCategories] = useState("Todos")
    return (
        <div className="home-page">
            <MenuLayouts />
            <BannerComponent />
            <CategoriesComponent selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} />
            <ContenidoComponent selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} />
            <FooterLayouts />
        </div>
    );
}

export default VehiculosPage;