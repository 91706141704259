import React from "react";
import combustible from "../../assets/03_Producto/Motor.svg";
import motor from "../../assets/03_Producto/Transmision.svg";
import garantia from "../../assets/03_Producto/Garantia.svg";

/*eslint-disable */

declare const window: any;

interface ContenidoComponentProps {
  setSelectedCategories: any,
  selectedCategories: any
}

const vehiculos: any = [
  {
    category: "Híbridos",
    vehiculos: [
      {
        name: "KONA Híbrida N Line",
        img: "./assets/portafolios/KONA_Prueba.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "KONA Híbrida",
        img: "./assets/portafolios/KONA_Prueba.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
    ],
  },
  {
    category: "Eléctricos",
    vehiculos: [
      {
        name: "IONIQ 5",
        img: "./assets/portafolios/Ioniq5.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "KONA eléctrica",
        img: "./assets/portafolios/KONA_Prueba.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
    ],
  },
  {
    category: "Automóviles",
    vehiculos: [
      {
        name: "HB20 Sedán",
        img: "./assets/portafolios/HB20S.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "HB20 Hatchback",
        img: "./assets/portafolios/HB20.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
    ],
  },
  {
    category: "SUV",
    vehiculos: [
      {
        name: "KONA Gasolina",
        img: "./assets/portafolios/KONA_Prueba.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "TUCSON",
        img: "./assets/portafolios/Tucson-NX4.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "PALISADE",
        img: "./assets/portafolios/Palisade.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "CRETA",
        img: "./assets/portafolios/Creta.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "VENUE",
        img: "./assets/portafolios/Venue.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
    ],
  },
  {
    category: "Vanes",
    vehiculos: [
      {
        name: "STARIA Pasajeros",
        img: "./assets/portafolios/STARIA.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "STARIA Lujo",
        img: "./assets/portafolios/STARIA.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "STARIA PANEL servicio público",
        img: "./assets/portafolios/STARIA.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
    ],
  },
  {
    category: "Comerciales",
    vehiculos: [
      {
        name: "STARIA PANEL servicio público",
        img: "./assets/portafolios/Metro-ACCENT.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
      {
        name: "GRAND METRO Taxi",
        img: "./assets/portafolios/Metro-ACCENT.webp",
        precio: "desde $ X00.000.000",
        combustible: "Tipo de Transmisión",
        motor: "Tipo de Motor",
        garantia: "Garantía X años o X00.000 Km",
        nuevo: false,
      },
    ],
  },
];

const ContenidoComponent: React.FC<ContenidoComponentProps> = ({setSelectedCategories, selectedCategories}) => {
  return (
    <div className="contenido-vehiculos-component">
      {vehiculos
        .filter(
          (categoria: any) =>
            selectedCategories === "Todos" ||
            categoria.category === selectedCategories
        )
        .map((categoria: any) => (
          <div
            className="categoria-contenido-vehiculos-component"
            key={categoria.category}
          >
            <div className="title-categoria-contenido-vehiculos-component">
              {categoria.category}
            </div>
            <div className="vehiculos-categoria-contenido-vehiculos-component">
              {categoria.vehiculos.map((vehiculo: any) => (
                <div
                  className="vehiculo-categoria-contenido-vehiculos-component"
                  key={vehiculo.name}
                >
                  <img
                    className="img-vehiculo-categoria-contenido-vehiculos-component"
                    src={vehiculo.img}
                    alt={vehiculo.name}
                  />
                  <h1 className="label-vehiculo-categoria-contenido-vehiculos-component">
                    {vehiculo.name}
                  </h1>
                  <p className="precio-vehiculo-categoria-contenido-vehiculos-component">
                    {vehiculo.precio}
                  </p>
                  <div className="detalle-vehiculo-categoria-contenido-vehiculos-component">
                    <div>
                      <img src={combustible} alt="Combustible" />
                      <span>{vehiculo.combustible}</span>
                    </div>
                    <div>
                      <img src={motor} alt="Motor" />
                      <span>{vehiculo.motor}</span>
                    </div>
                    <div>
                      <img src={garantia} alt="Garantía" />
                      <span>{vehiculo.garantia}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ContenidoComponent;
